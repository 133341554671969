<template>
  <div class="types-page-wrapper">
    <!-- <basic-search
      v-model="search"
      style="margin-bottom: 34px"
      :placeholder="'Search...'"
      :width="'340px'"
      :widthInput="'301px'"
      @update:model-value="getTaskTypes"
    /> -->
    <!-- <basic-button
      v-if="checkPermission(['tasktypes-add'])"
      :label="'New task type'"
      :image="'plus'"
      :imageDisabled="plus"
      style="white-space: nowrap; margin-bottom: 34px"
      @click="openCreateModal"
    /> -->
    <!-- <basic-list
      :data="listTaskTypes"
      :label="'ttp_name'"
      style="max-height: calc(100vh - 370px)"
      :odd="true"
      @edit="openEditModal"
      @delete="openConfirmModal"
    /> -->

    <table-wrapper
      :data="listTaskTypes"
      :options="options"
      upSpaceDown="63px"
      style="max-width: fit-content"
      @search="onSearch"
      @delete-row="openConfirmModal"
      @edit="openEditModal"
      @create="openCreateModal"
      @change-value-checkbox="changeValueCheckbox"
    />
    <pagination-one
      v-if="count > 0"
      v-model:page="page"
      :perPage="perPage"
      :offset="offset"
      :count="count"
      prevText="Prev"
      nextText="Next"
      style="margin: auto"
      @page-changed="onChange"
    />
  </div>
</template>
<script setup lang="ts">
import plus from "@/components/elements/table/plus.svg";
import api from "@/api";
import { CompanyStore } from "@/store/companyStore";
import { ModalStore } from "@/store/modalStore";
import { type Modal, type TaskType } from "@/assets/js/types";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";

import { usePagination } from "@/assets/js/composables";

const { count, offset, onChange, resetPagination, page, perPage } =
  usePagination(getTaskTypes, 11);

const modalStore = ModalStore();
const companyStore = CompanyStore();
const taskTypes = ref<TaskType[]>([]);
const search = ref<string | null>(null);

const listTaskTypes = computed(() => {
  return taskTypes.value.map((el) => {
    const res: any = el;
    if (checkPermission(["tasktypes-add"])) {
      res.editable = true;
      res.deletable = true;
    }

    return res;
  });
});

const options = ref({
  title: "",
  noData: "No task types available.",
  passive: true,
  searchable: true,
  creatable: checkPermission(["tasktypes-add"]),
  editable: checkPermission(["tasktypes-add"]),
  deletable: checkPermission(["tasktypes-add"]),
  sortable: false,
  type: "estimate",
  id: ["ttp_id"],
  columns: [
    {
      key: "ttp_name",
      type: "title",
      label: "Name",
      style: {
        width: "320px",
      },
    },
    {
      key: "ttp_points",
      // type: "title",
      label: "EP",
      style: {
        "max-width": "150px",
      },
    },
    {
      key: "ttp_defaultepcoef",
      // type: "text",
      label: "Default EP",
      style: {
        "max-width": "150px",
      },
    },
    {
      key: "ttp_billable",
      type: "checkbox",
      label: "Billable",
      style: {
        "max-width": "100px",
      },
    },
  ],
});

if (checkPermission(["tasktypes-add"])) {
  options.value.columns.push({
    key: "",
    type: "edit",
    label: "",
    style: {
      "max-width": "initial",
    },
  });
  options.value.columns.push({
    key: "",
    type: "delete",
    label: "",
    style: {
      "max-width": "initial",
    },
  });
}

onMounted(() => {
  getTaskTypes();
});

watch(
  () => companyStore.activeCompany,
  () => {
    resetPagination();
  }
);

function onSearch(data: string) {
  search.value = data;
  resetPagination();
}

async function getTaskTypes() {
  if (!companyStore.getActiveCompany?.cmp_id) {
    return;
  }
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      offset: offset.value - 1,
      limit: perPage.value,
    };
    if (search.value && search.value !== "") {
      params.search = search.value;
    }
    const res = await api.getTaskTypes(params);
    taskTypes.value = res.data.data;
    count.value = res.data.meta_info.count;
  } catch (error) {
    console.log(error);
  }
}

async function changeValueCheckbox(e: any) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      ttp_id: e.data.ttp_id,
      ttp_billable: e.e,
    };
    await api.editTaskType(e.data.ttp_id, params);
    getTaskTypes();
    openCustomToast("You have successfully changed the task type", "success");
  } catch (error: any) {
    getTaskTypes();
    openCustomToast("An error occurred while changing the task type", "error");
  }
}

function openConfirmModal(taskType: TaskType) {
  const data: Modal = {
    component: "confirmModal",
    title: "Delete task type?",
    options: {
      confirmText: "Confirm",
      subtitle: {
        template: `Task type: <strong>${taskType.ttp_name} </strong><br>`,
        data() {
          return { taskType };
        },
      },
      confirm: () => {
        deleteTaskType(taskType);
      },
      cancelText: "Cancel",
      cancel: () => {},
    },
  };
  modalStore.setModalData(data);
}

async function deleteTaskType(taskType: TaskType) {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
      ttp_id: taskType.ttp_id,
    };
    await api.deleteTaskType(params.ttp_id, params);
    openCustomToast("You have successfully deleted the task type.", "success");
  } catch (error) {
    openCustomToast(
      "An error occurred while deleting this task type.",
      "error"
    );
  } finally {
    // getTaskTypes();
    resetPagination();
  }
}

function openEditModal(taskType: TaskType) {
  const data: Modal = {
    component: "editTaskTypeModal",
    title: "Edit task type",
    options: {
      data: taskType,
      update: () => {
        getTaskTypes();
      },
    },
  };
  modalStore.setModalData(data);
}

function openCreateModal() {
  const modal: any = {
    title: "Add task type",
    component: "newTaskTypeModal",
    options: {
      update: () => {
        getTaskTypes();
      },
    },
  };
  modalStore.setModalData(modal);
}
</script>
<style lang="scss" scoped>
.types-page-wrapper {
  padding-top: 55px;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
