<template>
  <div class="forgot-password-modal-wrapper">
    <div class="text-wrapper">
      Please enter the email address you would like your password reset
      information sent to.
    </div>
    <div class="inputs-wrapper">
      <basic-input
        v-model="email"
        label="Enter email address"
        :setFocus="true"
        @keyup.enter="RequestNewPassword"
      />
    </div>
    <div class="inputs-wrapper wrB">
      <basic-button label="Request reset link" @click="RequestNewPassword" />
    </div>
    <div class="login-link">
      <span @click="closeAndNavigate('LoginPage')">Back to Login</span>
    </div>
    <TurnStile
      v-model="token"
      :resetToken="resetToken"
      action="forgot-password"
    ></TurnStile>
  </div>
</template>
<script setup lang="ts">
import router from "@/router";
import { ModalStore } from "../../store/modalStore";
// import { useReCaptcha } from "vue-recaptcha-v3";
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";
import { transl } from "@/i18n";

const modalStore = ModalStore();
const email = ref<string | null>(null);
// const recaptcha = useReCaptcha();
const token = ref("");
const resetToken = ref(false);

function closeAndNavigate(route: string): void {
  modalStore.setModalData(null);
  setTimeout(() => {
    router.push({ name: route });
  }, 300);
}

async function RequestNewPassword() {
  // await recaptcha?.recaptchaLoaded();
  // const token = await recaptcha?.executeRecaptcha("forgotPassword");

  const params: any = {
    email: email.value,
    google_token: token.value,
  };
  resetToken.value = !resetToken.value;

  try {
    await api.forgotPassword(params);
    openCustomToast("Email sent", "success", "Check your inbox!");
    router.push({ name: "LoginPage" });
  } catch (error: any) {
    const errData: any = error.response.data;
    if (errData.validation && errData.name) {
      openCustomToast(
        transl(`error.forgot-password.${errData.validation}.${errData.name}`),
        "error"
      );
    } else if (errData.message === "invalid-email") {
      openCustomToast(transl(`error.forgot-password.email-not-found`), "error");
    }
  }
}
</script>
<style lang="scss" scoped>
.forgot-password-modal-wrapper {
  .inputs-wrapper {
    margin-bottom: 15px;
  }
  .inputs-wrapper.wrB {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .text-wrapper {
    margin-bottom: 20px;
    max-width: 299px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }

  .login-link {
    margin-top: 10px;
    margin-bottom: 28px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    span {
      color: var(--vt-c-active);
      // font-weight: 700;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>
