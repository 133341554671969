<template>
  <div class="forgot-password-modal-wrapper">
    <div class="text-wrapper">Please enter your new password</div>
    <div class="inputs-wrapper">
      <basic-input
        v-model="password"
        type="password"
        label="Password"
        :setFocus="true"
        @keyup.enter="saveChanges"
      />
    </div>
    <div class="inputs-wrapper wrB">
      <basic-button label="Save changes" @click="saveChanges" />
    </div>
    <div class="login-link">
      <span @click="closeAndNavigate('LoginPage')">Back to Login</span>
    </div>
    <TurnStile
      v-model="localToken"
      :resetToken="resetToken"
      action="new-password"
    ></TurnStile>
  </div>
</template>
<script setup lang="ts">
import router from "@/router";
import { ModalStore } from "../../store/modalStore";
// import { useReCaptcha } from "vue-recaptcha-v3";
import api from "@/api";
import { openCustomToast } from "@/assets/js/helpers";
import { transl } from "@/i18n";

const modalStore = ModalStore();
const password = ref<string | null>(null);
// const recaptcha = useReCaptcha();
const token = ref<string | null>(null);
const localToken = ref("");
const resetToken = ref(false);

onMounted(() => {
  if (router.currentRoute.value.query.token) {
    token.value = String(router.currentRoute.value.query.token);
  } else {
    openCustomToast('Parameter "Token" not found.', "error");
    router.push({ name: "LoginPage" });
  }
});

function closeAndNavigate(route: string): void {
  modalStore.setModalData(null);
  setTimeout(() => {
    router.push({ name: route });
  }, 300);
}

async function saveChanges() {
  // await recaptcha?.recaptchaLoaded();
  // const google_token = await recaptcha?.executeRecaptcha("newPassword");

  const params: any = {
    password: password.value,
    google_token: localToken.value,
    token: token.value,
  };
  resetToken.value = !resetToken.value;

  try {
    await api.newPassword(params);
    openCustomToast(
      "Password successfuly changed",
      "success",
      "You can now login"
    );
    router.push({ name: "LoginPage" });
  } catch (error: any) {
    const errData: any = error.response.data;
    if (errData.message) {
      openCustomToast(
        transl(`error.forgot-password.${errData.message}`),
        "error"
      );
    }
  }
}
</script>
<style lang="scss" scoped>
.forgot-password-modal-wrapper {
  .inputs-wrapper {
    margin-bottom: 15px;
  }
  .wrB {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .text-wrapper {
    margin-bottom: 30px;
    max-width: 290px;
    // font-weight: 400;
    // font-size: 14px;
    // line-height: 19px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }

  .login-link {
    margin-top: 10px;
    margin-bottom: 28px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    span {
      color: var(--vt-c-active);
      font-weight: 700;
      cursor: pointer;
    }
  }
}
</style>
