<template>
  <div class="register-modal-wrapper">
    <div class="inputs-wrapper">
      <basic-input
        v-model="params.name"
        label="First name"
        :setFocus="true"
        @keyup.enter="register"
      />
    </div>
    <div class="inputs-wrapper">
      <basic-input
        v-model="params.lastname"
        label="Last name"
        @keyup.enter="register"
      />
    </div>
    <div class="inputs-wrapper">
      <basic-input
        v-model="params.email"
        label="Email"
        @keyup.enter="register"
      />
    </div>
    <!-- <div class="inputs-wrapper">
      <basic-input
        v-model="params.phone"
        label="Phone number"
        @keyup.enter="register"
      />
    </div> -->
    <div class="inputs-wrapper">
      <basic-input
        v-model="params.displayname"
        label="Display name"
        @keyup.enter="register"
      />
    </div>
    <div class="inputs-wrapper">
      <basic-input
        v-model="params.password"
        label="Password"
        type="password"
        @keyup.enter="register"
      />
    </div>
    <div class="inputs-wrapper">
      <basic-input
        v-model="confirmPassword"
        label="Confirm password"
        type="password"
        @keyup.enter="register"
      />
    </div>
    <div class="inputs-wrapper wrB">
      <basic-button label="Register" @click="register" />
    </div>
    <div class="login-link">
      Already have an account?
      <span @click="closeAndNavigate('LoginPage')">Login</span>
    </div>
    <TurnStile
      v-model="token"
      :resetToken="resetToken"
      action="register"
    ></TurnStile>
  </div>
</template>
<script setup lang="ts">
import { ModalStore } from "../../store/modalStore";
import api from "@/api";
import { transl } from "@/i18n";
import { openCustomToast } from "@/assets/js/helpers";
import { useRouter } from "vue-router";
// import { useReCaptcha } from "vue-recaptcha-v3";

const router = useRouter();
const modalStore = ModalStore();
// const recaptcha = useReCaptcha();
const token = ref("");
const resetToken = ref(false);

type RegisterParams = {
  name: string | null;
  lastname: string | null;
  email: string | null;
  displayname: string | null;
  password: string | null;
  google_token?: string;
  // phone?: string;
};

const params = reactive<RegisterParams>({
  name: null,
  lastname: null,
  email: null,
  displayname: null,
  password: null,
});
const confirmPassword = ref<string | null>(null);

async function register() {
  try {
    if (confirmPassword.value !== params.password) {
      openCustomToast(
        'The fields "Password" and "Confirm password" are not the same',
        "error"
      );
      return;
    }
    // recaptcha:
    // await recaptcha?.recaptchaLoaded();
    // const token = await recaptcha?.executeRecaptcha("register");
    params.google_token = token.value;
    resetToken.value = !resetToken.value;

    // register:
    await api.register(params);
    openCustomToast(
      "You have successfully registered.",
      "success",
      "A validation email has been sent to you."
    );
    router.push({ name: "LoginPage" });
  } catch (error: any) {
    const errData: any = error.response.data;
    if (errData.validation && errData.name) {
      openCustomToast(
        transl(`error.register.${errData.validation}.${errData.name}`),
        "error"
      );
    } else if (errData.message) {
      openCustomToast(transl(`error.register.${errData.message}`), "error");
    }
  }
}

function closeAndNavigate(route: string): void {
  modalStore.setModalData(null);
  setTimeout(() => {
    router.push({ name: route });
  }, 300);
}
</script>
<style lang="scss" scoped>
.register-modal-wrapper {
  .inputs-wrapper {
    margin-bottom: 15px;
  }
  .inputs-wrapper.wrB {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .login-link {
    margin-top: 10px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 28px;

    span {
      color: var(--vt-c-active);
      font-weight: 700;
      cursor: pointer;
    }
  }
}
</style>
