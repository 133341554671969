<template>
  <div class="edit-status">
    <div class="wrapp-interactions">
      <div class="calendar">
        <BasicDropdown
          class="statuses"
          :list="statuses"
          :selected="selectedStatus"
          labelKey="avt_name"
          style="width: 275px"
          @selected="setSelectedStatus"
        />
        <Datepicker
          ref="datepicker"
          :model-value="selectedDate"
          :enable-time-picker="false"
          :clearable="false"
          format="dd.MM.yyyy"
          :auto-apply="true"
          :dark="true"
          input-class-name="datepicker-input"
          menu-class-name="datepicker-dropdown"
          :inline="true"
          :range="true"
          :multi-calendars="false"
          :markers="markers"
          :min-date="new Date()"
          @update:model-value="handleDate"
          @update-month-year="handleMonthYear"
        >
          <template #marker-tooltip="{ tooltip, day }">
            <div
              :style="{
                color: tooltip.color,
                cursor: canChange(day) ? 'pointer' : '',
              }"
              @click="getTooltipData(tooltip, day)"
            >
              {{ tooltip.text }}
            </div>
          </template>
        </Datepicker>
        <!-- :range="true"
                :multi-calendars="true"  -->
      </div>
      <div class="description">
        <BasicDropdown
          v-if="checkPermission(['availability-manage'])"
          class="statuses"
          title="Select user"
          :list="usersList"
          :selected="selectedUserForStatus"
          labelKey="usr_displayname"
          style="width: 275px"
          @selected="setSelectedUser"
        />

        <basicInput
          v-model="txt"
          placeholder="You can add additional details here..."
          label="Notes"
          type="textarea"
          :costumHeight="
            checkPermission(['availability-manage']) ? '262px' : '310px'
          "
        >
        </basicInput>
      </div>
    </div>
    <!-- <div v-for="(el, ind) in selectedEvents" :key="ind" style="color: red">
      {{ el }}
    </div> -->
    <BasicButton
      label="Set status"
      :width="'114px'"
      :disabled="false"
      @click="checkParams"
    />
  </div>
</template>
<script lang="ts" setup>
// import { ModalStore } from "@/store/modalStore";
import { CompanyStore } from "@/store/companyStore";
import { UserStore } from "@/store/userStore";
import { checkPermission, openCustomToast } from "@/assets/js/helpers";
import Datepicker from "@vuepic/vue-datepicker";
import dayjs from "dayjs";
// import addDays from "date-fns/addDays";
import api from "@/api";

defineEmits(["close"]);
const props = defineProps(["options"]);

// const modalStore = ModalStore();
const companyStore = CompanyStore();
const userStore = UserStore();

interface IAvailability {
  avt_id: number;
  avt_name: string;
  avt_color?: string | null;
}
interface IMarkers {
  date?: Date | string;
  type: "line" | "dot";
  color: string;
  tooltip?: any[];
}

const statuses = ref<IAvailability | []>([]);
const usersList = ref<any>([]);

// params
const selectedStatus = ref<IAvailability | null>(null);
const selectedDate = ref<any>();
const ava_from = ref<any>();
const ava_to = ref<any>();
const txt = ref<string>("");

const selectedEvents = ref<any>([]);
// user
const selectedUserForStatus = ref<any>(null);

const markers = ref<IMarkers[] | []>([
  //   {
  //     date: addDays(new Date(), 2),
  //     type: 'line',
  //     color: "#FBB209",
  //     tooltip: [ // moze a ne mora
  //       { text: 'First tooltip', color: '#F33260' },
  //       { text: 'Second tooltip', color: '#FBB209' },
  //     ],
  //   },
]);

const dateForMarkers = ref<any>();

async function getStatuses() {
  if (!companyStore.activeCompany?.cmp_id) {
    return;
  }
  const params = {
    cmp_id: companyStore.activeCompany?.cmp_id,
  };
  try {
    const res = await api.getAvailabilityTypes(params);
    console.log(res);
    statuses.value = res.data.data;
    selectedStatus.value = statuses.value[0];
  } catch (e) {
    console.log(e);
  }
}
getStatuses();

const handleMonthYear = ({ instance, month, year }) => {
  console.log(instance + ", " + month + ", " + year);
  let date = new Date(year, month, 10);
  viewAvailbility(date);
};

async function viewAvailbility(e: any) {
  resetFields();
  console.log(e);
  let date = e ? e : new Date();
  dateForMarkers.value = date;

  // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  // let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  let firstDay = dayjs.utc(date).startOf("month").format("YYYY-MM-DD");
  let lastDay = dayjs.utc(date).endOf("month").format("YYYY-MM-DD");
  let params = {
    cmp_id: companyStore.activeCompany?.cmp_id,
    view_from: firstDay,
    view_to: lastDay,
  };

  try {
    let res = await api.viewAvailbility(params);
    // console.log(res.data.data);
    redrawData(res.data.data);
  } catch (e) {
    console.log(e);
  }
}
viewAvailbility(null);

function redrawData(e: any) {
  console.log(e);
  // one marker
  //    {
  //     date: addDays(new Date(), -4),
  //     type: 'dot',
  //     color: "#51C38D",
  //     tooltip: [{ text: 'Dot with tooltip', color: '#51C38D' }],
  //   },
  markers.value = [];
  for (let i = 0; i < e.length; i++) {
    let obj: IMarkers = {
      date: e[i].date,
      type: e[i].users.length > 1 ? "line" : "dot",
      color:
        e[i].users.length === 1 ? "#" + e[i].users[0].avt_color : "#F33260",
      tooltip: [],
    };

    for (let j = 0; j < e[i].users.length; j++) {
      let help = e[i].users[j];
      help.text = e[i].users[j].usr_displayname;
      help.color = "#" + e[i].users[j].avt_color;
      (help.date = e[i].date), obj.tooltip?.push(help);
    }
    markers.value.push(obj);
  }
}

function getTooltipData(e: any, day: any) {
  if (!canChange(day)) {
    return;
  }
  console.log(e);
  console.log(day);
  selectedEvents.value = [e];
  selectedUserForStatus.value = e;
  ava_from.value = e.date;
  ava_to.value = e.date;
  selectedDate.value = [e.date, e.date];
  selectedStatus.value = {
    avt_id: e.avt_id,
    avt_name: e.avt_name,
    avt_color: e.avt_color,
  };
  txt.value = e.ava_description;
}

function setSelectedStatus(e: any) {
  selectedStatus.value = e;
}
function setSelectedUser(e: any) {
  selectedUserForStatus.value = e;
}

function checkParams() {
  if (!ava_from.value) {
    openCustomToast("Date is required.", "error");
    return false;
  }
  if (!selectedStatus.value) {
    openCustomToast("Availability status is required", "error");
    return false;
  }
  if (txt.value && txt.value.length > 255) {
    openCustomToast("Notes field is too long", "error");
    return false;
  }

  // call api
  if (
    selectedStatus.value.avt_id === 1 ||
    selectedStatus.value.avt_name.toLowerCase() === "working"
  ) {
    if (!selectedUserForStatus.value?.usr_id) {
      openCustomToast("User is required", "error");
      return false;
    }
    // call delete api
    deleteStatus();
  } else if (
    selectedUserForStatus.value &&
    selectedUserForStatus.value.ava_id &&
    selectedEvents.value.length > 0
  ) {
    editStatus();
  } else {
    setStatus();
  }
}

async function setStatus() {
  console.log("setStatus - call api");

  type SetParam = {
    cmp_id: any;
    ava_from: any;
    ava_to: any;
    avt_id: any;
    ava_description: any;
    usr_id?: number;
  };
  let params: SetParam = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    ava_from: ava_from.value,
    ava_to: ava_to.value,
    avt_id: selectedStatus.value?.avt_id,
    ava_description: txt.value,
  };

  if (
    selectedUserForStatus.value?.usr_id &&
    checkPermission(["availability-manage"])
  ) {
    params.usr_id = selectedUserForStatus.value.usr_id;
  }

  try {
    const res = await api.addAvailability(params);
    console.log(res.data);
    openCustomToast("Successfully add", "success");
    viewAvailbility(dateForMarkers.value);
    props.options.update();
  } catch (e: any) {
    if (e.response.status === 400 && e.response.data.message) {
      openCustomToast(e.response.data.message, "error");
    } else {
      openCustomToast("Error", "error");
    }
  }
}

async function deleteStatus() {
  let help: any = null;
  // if(!selectedUserForStatus.value || (!selectedUserForStatus.value.ava_id && !selectedUserForStatus.value.usr_id)) {
  //     openCustomToast("This status is already for this date.", "error");
  //     return;
  if (selectedUserForStatus.value?.usr_id) {
    help = getAvaId1(selectedUserForStatus.value.usr_id);
  }

  type SetParam = {
    cmp_id: any;
    ava_from: any;
    ava_to: any;
    ava_id: any;
    usr_id?: number;
  };

  let params: SetParam = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    ava_from: ava_from.value,
    ava_to: ava_to.value,
    ava_id: help, // selectedUserForStatus.value.ava_id,
  };
  if (
    selectedUserForStatus.value?.usr_id &&
    checkPermission(["availability-manage"])
  ) {
    params.usr_id = selectedUserForStatus.value.usr_id;
  }

  try {
    const res = await api.deleteAvailbility(params);
    console.log(res.data);
    openCustomToast("Successfully set working days. ", "success");
    viewAvailbility(dateForMarkers.value);
    props.options.update();
  } catch (e: any) {
    console.log(e.response.data.message);
    if (e.response.status === 400 && e.response.data.message) {
      openCustomToast(e.response.data.message, "error");
    } else {
      openCustomToast("Error", "error");
    }
  }
}

async function editStatus() {
  // ava_id je id odmora
  // avt_id je tip odmora

  let params = {
    cmp_id: companyStore.getActiveCompany?.cmp_id,
    ava_from: ava_from.value,
    ava_to: ava_to.value,
    avt_id: selectedStatus.value?.avt_id,
    ava_id: selectedUserForStatus.value.ava_id,
    ava_description: txt.value,
  };

  try {
    const res = await api.editAvailability(params);
    console.log(res.data);
    openCustomToast("Successfully edit", "success");
    viewAvailbility(dateForMarkers.value);
    props.options.update();
  } catch (e: any) {
    if (e.response.status === 400 && e.response.data.message) {
      openCustomToast(e.response.data.message, "error");
    } else {
      openCustomToast("Error", "error");
    }
  }
}

const handleDate = (modelData: any) => {
  console.log(modelData);
  selectedDate.value = modelData;
  ava_from.value = dayjs.utc(modelData[0]).local().format("YYYY-MM-DD");
  ava_to.value = dayjs.utc(modelData[1]).local().format("YYYY-MM-DD");
  if (!checkPermission(["availability-manage"])) {
    selectedUserForStatus.value = { usr_id: userStore.getUser?.id };
  }

  return;
  /*
  console.log(selectedDate.value);
  // get number of days betwen
  const diffInMs: any   = (new Date(ava_to.value) - new Date(ava_from.value));
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  console.log(diffInDays);

    let arrayMarker = [];
  for(let i = 0; i <= diffInDays; i++){
    // Create new Date instance
    let date = new Date(ava_from.value);
    // Add a day
    // console.log( date.setDate(date.getDate() + i));
    let compareDate = dayjs.utc(date.setDate(date.getDate() + i)).local().format("YYYY-MM-DD");
     let arr = markers.value.filter(
        (el) => dayjs.utc(el.date).local().format("YYYY-MM-DD") === compareDate
     );
     console.log(compareDate);
     console.log(arr);
     arrayMarker =  arrayMarker.concat(arr);
  }
  selectedEvents.value = arrayMarker;
  console.log(arrayMarker);
//   selectedEvents.value = markers.value.filter(
//     (el) => dayjs.utc(el.date).local().format("YYYY-MM-DD") === ava_from.value
//   );

if(selectedEvents.value[0]?.tooltip && selectedEvents.value[0].tooltip[0]){
  selectedUserForStatus.value = selectedEvents.value[0].tooltip[0];
    selectedStatus.value= {
        avt_id: selectedUserForStatus.value.avt_id,
        avt_name: selectedUserForStatus.value.avt_name,
        avt_color: selectedUserForStatus.value.avt_color,
    };
  txt.value= selectedUserForStatus.value.ava_description;
  }
  //

  */
};

function canChange(day: any) {
  return (
    checkPermission(["availability-manage"]) ||
    new Date(new Date().setHours(0, 0, 0, 0)).getTime() <= day.getTime()
  );
}

async function getUsers() {
  try {
    const params: any = {
      cmp_id: companyStore.getActiveCompany?.cmp_id,
    };
    const res = await api.searchUsers(params);
    usersList.value = res.data.data;
  } catch (error) {
    console.log(error);
  }
}
getUsers();

function resetFields() {
  selectedStatus.value = statuses.value[0];
  selectedDate.value = null;
  ava_from.value = null;
  ava_to.value = null;
  txt.value = "";
  selectedUserForStatus.value = null;
}

function getAvaId1(usrId: any) {
  // get number of days betwen
  const diffInMs: any | number =
    new Date(ava_to.value) - new Date(ava_from.value);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  console.log(diffInDays);

  let arrayMarker = [];
  for (let i = 0; i <= diffInDays; i++) {
    let date = new Date(ava_from.value);

    let compareDate = dayjs
      .utc(date.setDate(date.getDate() + i))
      .local()
      .format("YYYY-MM-DD");
    let arr = markers.value.filter(
      (el) => dayjs.utc(el.date).local().format("YYYY-MM-DD") === compareDate
    );

    if (arr.length > 0) {
      arrayMarker = arrayMarker.concat(arr[0].tooltip);
    }
  }

  if (usrId) {
    selectedEvents.value = arrayMarker.filter((el: any) => el.usr_id === usrId);
  } else {
    selectedEvents.value = arrayMarker;
  }

  console.log(selectedEvents.value);
  let help: any | number[] = [];
  for (let i = 0; i < selectedEvents.value.length; i++) {
    help.push(selectedEvents.value[i].ava_id);
  }
  return help;
}

onMounted(() => {
  // console.log(datepicker.value)
});
</script>
<style lang="scss" scoped>
.edit-status {
  .wrapp-interactions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 45px;

    .calendar {
      margin-right: 35px;
    }
    .statuses {
      margin-bottom: 15px;
    }
    .datepicker-dropdown {
      width: 275px;
    }
  }
}
</style>
<style lang="scss">
.edit-status {
  // datepicker style
  .dp__menu {
    min-width: 275px;
  }
  // button style
  .btn-wrapper-two {
    margin-left: auto;
  }
  //input style
  .input-wrapper {
    width: 275px;
  }

  .outer-dropdown-wrapper {
    .title {
      display: none;
    }
  }
}
</style>
